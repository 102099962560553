
import "./solar-page.scss";
import { Options, setup, Vue } from "vue-class-component";
import { useMeta } from "vue-meta";
import Page from "@/components/page/page.vue";
import Panel from "@/components/panel/panel.vue";
import {
  getImageUrlFromContentfulEntity,
  getProductBlockFromContentfulEntity,
} from "@/store/utils";
import { ProductBlock } from "@/store/common.types";
import { FETCH_SOLAR_PRODUCT_PAGE } from "@/store/modules/product-page/product-page.actions";
import { SOLAR_PRODUCT_PAGE_ENTRY_ID } from "@/store/api.config";
import { ProductPageEntity } from "@/store/modules/product-page/product-page.model";
import ProductBlockComponent from "@/components/product-block/product-block.vue";

@Options({
  name: "SolarPage",
  components: {
    ProductBlockComponent,
    Page,
    Panel,
  },
})
export default class SolarPage extends Vue {
  //#region Meta
  meta = setup(() =>
    useMeta({
      title: "Solar",
      htmlAttrs: { lang: "en", amp: true },
    })
  );
  //#endregion

  //#region Component
  isLoading = true;
  solarProductPageEntity: ProductPageEntity | null = null;
  productBlocks: Array<ProductBlock> | null = null;
  //#endregion

  //#region Hooks
  created(): void {
    this.setup();
  }

  async setup(): Promise<void> {
    await this.$store.dispatch(
      FETCH_SOLAR_PRODUCT_PAGE,
      SOLAR_PRODUCT_PAGE_ENTRY_ID
    );
    this.solarProductPageEntity = this.$store.getters.solarProductPageEntity;

    this.productBlocks = [];
    this.solarProductPageEntity.productPageProductBlocks.forEach(
      async (entityBlock) => {
        await getProductBlockFromContentfulEntity(entityBlock).then(
          (productBlock) => this.productBlocks.push(productBlock)
        );
      }
    );

    this.isLoading = false;
  }
  //#endregion

  //#region Functions
  getSolarProductPageSplashImageUrl(): string {
    return getImageUrlFromContentfulEntity(
      this.solarProductPageEntity.productPageSplashImage
    );
  }
  //#endregion
}
SolarPage.registerHooks([
  "beforeRouteEnter",
  "beforeRouteUpdate",
  "beforeRouteLeave",
]);


import "./product-block.scss";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ProductResourceEntity } from "@/store/modules/product-page/product-page.model";
import { ProductBlock, ProductResource } from "@/store/common.types";
import Applink from "@/components/applink/applink.vue";
import { FETCH_PRODUCT_RESOURCE } from "@/store/modules/product-page/product-page.actions";
import { NGrid, NGridItem } from "naive-ui";

@Options({
  name: "ProductBlock",
  components: {
    Applink,
    NGrid,
    NGridItem,
  },
})
export default class ProductBlockComponent extends Vue {
  //#region Component
  @Prop() productBlock: ProductBlock;

  isLoading = true;
  productResources: Array<ProductResource> | null = null;
  //#endregion

  //#region Hooks
  created(): void {
    this.setup();
  }

  async setup(): Promise<void> {
    this.productResources = [];
    await this.productBlock.resources.forEach(async (resource) => {
      await this.$store.dispatch(FETCH_PRODUCT_RESOURCE, resource.entryId);

      const stateResource: ProductResourceEntity =
        this.$store.getters.productResource;
      const stateFile: any = stateResource.productBlockResourceFile;

      this.productResources.push({
        entryId: stateResource.id,
        name: stateResource.productBlockResourceNameText,
        url: stateFile.fields.file.url,
      });
    });

    this.isLoading = false;
  }
  //#endregion
}

<template>
  <div v-if="!this.isLoading" class="product-block">
    <n-grid cols="1 300:1 600:3 850:3" :x-gap="10">
      <n-grid-item
        span="1 300:1 600:1 850:1"
        class="product-block__grid-item--images"
      >
        <img
          class="product-block__logo"
          :src="this.productBlock.logoImageUrl"
        />
        <img class="product-block__image" :src="this.productBlock.imageUrl" />
      </n-grid-item>
      <n-grid-item
        span="2 300:1 600:2 850:2"
        class="product-block__grid-item--details"
      >
        <h2>{{ this.productBlock.headerText }}</h2>
        {{ this.productBlock.text }}
        <h4>Resources</h4>
        <div v-for="(item, index) in this.productResources" :key="index">
          <Applink :key="item.name" :to="`http://${item.url}`">
            <font-awesome-icon :icon="['fas', 'file-pdf']" /> {{ item.name }}
          </Applink>
        </div>
      </n-grid-item>
    </n-grid>
  </div>
</template>

<script lang="ts">
import "./product-block.scss";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ProductResourceEntity } from "@/store/modules/product-page/product-page.model";
import { ProductBlock, ProductResource } from "@/store/common.types";
import Applink from "@/components/applink/applink.vue";
import { FETCH_PRODUCT_RESOURCE } from "@/store/modules/product-page/product-page.actions";
import { NGrid, NGridItem } from "naive-ui";

@Options({
  name: "ProductBlock",
  components: {
    Applink,
    NGrid,
    NGridItem,
  },
})
export default class ProductBlockComponent extends Vue {
  //#region Component
  @Prop() productBlock: ProductBlock;

  isLoading = true;
  productResources: Array<ProductResource> | null = null;
  //#endregion

  //#region Hooks
  created(): void {
    this.setup();
  }

  async setup(): Promise<void> {
    this.productResources = [];
    await this.productBlock.resources.forEach(async (resource) => {
      await this.$store.dispatch(FETCH_PRODUCT_RESOURCE, resource.entryId);

      const stateResource: ProductResourceEntity =
        this.$store.getters.productResource;
      const stateFile: any = stateResource.productBlockResourceFile;

      this.productResources.push({
        entryId: stateResource.id,
        name: stateResource.productBlockResourceNameText,
        url: stateFile.fields.file.url,
      });
    });

    this.isLoading = false;
  }
  //#endregion
}
</script>
